.quickLinkItem {
  position: relative;
  padding: 10px 10px 7px 10px !important;
  border-radius: 3px 3px 0 0;
  min-width: 8%;
  cursor: pointer;
  color: $simproBlue;
  height: 20px;
  margin-right: 3px;
  max-width: 20%;
  text-align: center;
  display: flex;
  &.activeQuickLink {
    background: white;
    cursor: default; }
  &:not(.activeQuickLink):hover {
    background: $lightestGrey; }
  &:before {
    // Company colour horizontal bar
    content: '';
    position: absolute;
    top: 8px;
    left: -2px;
    width: 1px;
    bottom: 8px;
    background-color: $tabContentBorderColour; }
  .companyColourLine {
    position: absolute;
    bottom: 5px;
    left: 15px;
    right: 15px;
    height: 1px; }
  .backLink {
    position: absolute;
    top: 5px;
    right: 3px;
    .backLink:hover {
      color: red; } } }

.quickLinkText {
  display: inline-block;
  flex-grow: 1;
  padding-right: 6px; }

.quickLinkIcon {
  margin: 2px 5px 0 0;
  font-size: 14px; }

.closeQuickLink {
  width: 15px;
  height: 15px;
  font-size: 15px;
  border-radius: 50%;
  color: $lightestGrey;
  background-color: $midDarkGrey;
  &:hover {
    background-color: $simproBlue; } }

.quickLinks {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 3px;
  height: 38px;
  padding-left: 12px; }

.quickLinkAction {
  padding-top: 40px;
  &--position {
    margin-left: 15px;
    padding-top: 15px;
    position: absolute;
    font-size: x-large;
    border-top: 1px solid lightgrey;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg); } }

#quickLinksTopBar {
  flex-grow: 1;
  min-width: 40%; }
#createCloseQuicklinkTitle {
  font-weight: bold; }
