#topBar-ui2 {
  background-color: #F0F1F2;
  position: fixed;
  display: flex;
  top: 0;
  left: $leftMenuCollapsedWidth_UI2;
  right: 0;
  height: $topBarHeight_UI2;
  z-index: $zIndex_topBar;
  transition: left .4s; // Timing to use when collapsing & expanding leftMenu
  .menuExpanded & {
    left: $leftMenuExpandedWidth_UI2; }
  .menuHidden & {
    left: 0; } }

#topBarItems-ui2 {
  padding: 0;
  list-style: none;
  z-index: 106;
  height: 40px;
  margin-right: 10px;
  white-space: nowrap;
  .alertsBubble {
    a {
      color: #F0F1F2;
      height: 22px;
      font-size: 10px;
      font-weight: normal; } }
  li {
    > a {
      [class^="icon"] {
        font-size: 18px;
        vertical-align: -11%; } } }
  .icon-logout {
    font-size: 18px; }
  .alertsBubble {
    position: relative;
    top: -8px; }
  .alertsBubble {
    @include bubble(20px,#D2232A);
    z-index: 1;
    a {
      color: #FFFFFF; } }
  .announcementBubble {
    @include bubble(25px,#FF7740); }
  > li {
    padding: 10px 13px 5px 13px;
    display: inline-block;
    float: none;
    position: relative;
    z-index: 10;
    &:hover {
      ul {
        left: 50%;
        top: 22px;
        margin-left: ($headerMenuWidth * -0.9);
        visibility: visible;
        opacity: 1;
        transition-delay: 250ms; } }
    ul {
      @extend %box_shadow;
      width: $headerMenuWidth;
      height: auto;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      z-index: 10;
      margin: 14px 0 0 -2px;
      padding-top: 5px;
      background: #fff;
      &:before {
        /* Add the little pointer arrow at the top of the menu */
        content: unicode("e011");
        font-family: 'v12-icons';
        width: 10px;
        height: 10px;
        font-size: 7px;
        color: #fff;
        position: absolute;
        top: -6px;
        left: 90%;
        margin-left: -5px;
        z-index: 11; }
      &.last {
        margin-left: -67px; }
      a {
        display: block;
        margin-left: 0; }
      li {
        #loggedUserText {
          color: #808080; }
        #loggedUserName {
          color: #000;
          font-weight: bold;
          white-space: normal; }
        &:hover {
          background: #BFDAFF; }
        a {
          padding: 6px 5px 3px 10px;
          font-size: 12px;
          color: $simproBlue;
          white-space: normal; } }
      .loggedUserContainer {
        padding: 6px 5px 6px 10px;
        border-bottom: 1px solid #ebebeb;
        &:hover {
          background: none; } } } } }

/** Global Search **/

#globalSearchContainer {
  height: 42px;
  width: auto !important;
  min-width: 20% !important;
  max-width: 350px !important;
  float: right;
  margin: 0;
  position: relative;
  @include media(overlaptop) {
    max-width: 250px; }
  @media only screen and (min-width: 1366px) and (max-width: 1420px) {
    max-width: 300px; }
  @media only screen and (min-width: 1420px) and (max-width: 1580px) {
    max-width: 400px; } }

#globalSearchForm, #toggleSavedSearches {
  border: 0 !important; }

#globalSearchForm {
  margin: 4px 8px 0 0; }

#globalSearchForm, #globalSearch {
  height: 30px !important; }

#toggleSavedSearches {
  background-color: $headerGrey !important;
  .icon-bookmark {
    font-size: 18px; } }

#globalSearch:focus {
  background-color: #fff; }

#globalSearchButton, .savedSearchBtn, #voipSettings, .topBarIcon {
  color: $simproBlue !important; }

.savedSearchBtn {
  margin-top: -4px /* offset margin from #globalSearchForm */;
  padding: 5px 5px 5px 20px !important; }

#globalSearchButton {
  margin-top: -4px;
  border-left: 0 !important;
  width: 35px !important; }

#savedSearchesCont {
  ul {
    margin-top: 12px !important;
    .menuContent {
      li {
        a:hover {
          background: #BFDAFF; } } } } }

/** Company Selector & Company Name Display **/
.companyNameBlock {
  padding: 5px 10px;
  height: 80%;
  width: 13%;
  margin: 5px 0 0 15px;
  display: inline;
  float: left;
  box-sizing: border-box;
  .companyDiv-ui2 {
    display: flex; } }

#companySelectorDiv {
  cursor: pointer; }

#companyIconDiv {
  flex-basis: 20px;
  flex-shrink: 0;
  margin-right: 5px;
  font-size: 16px; }

#companyNameDiv {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px; }

#companyListChevronDiv {
  margin-top: -3px;
  flex-basis: 20px;
  flex-shrink: 0;
  font-size: 20px;
  color: #17bae5; }

#companySelector-ui2:hover {
  border-radius: 5px 5px 0 0;
  background-color: #BFDAFF; }

#companyDropDown {
  position: absolute;
  overflow-y: auto;
  z-index: 99;
  background: #FFFFFF;
  margin-left: 0 !important;
  display: none;
  border-left: 1px solid #d5d5d5;
  border-right: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  padding: 5px;
  max-height: 600px;
  left: 15px;
  .companyChange {
    padding: 5px;
    display: flex;
    cursor: pointer;
    &:hover {
      background-color: #BFDAFF; }
    .companyIconDiv {
      font-size: 16px;
      margin-right: 7px;
      margin-left: 2px; }
    .companyNameDiv {
      font-size: 14px; } } }

#headerVoip {
  visibility: visible; }

#headerAlerts, #pushNotificationsBubble {
  visibility: hidden;
  margin-left: -20px; }

.greyScrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 6px;
  background-color: white; }

.greyScrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: white; }

.greyScrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: darkgrey; }

// Show results table header below top bar for sticky header
thead.tableFloatingHeaderOriginal {
  top: $topBarHeight_UI2 !important; }

// On small screens, force the menu into compact mode
@media screen and (max-width: 1400px) {
  .menuExpanded {
    #topBar-ui2 {
      left: $leftMenuCollapsedWidth_UI2; } } }
