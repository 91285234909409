@font-face {
  font-family: 'v12-icons';
  src: url('fonts/v12-icons.eot');
  src: url('fonts/v12-icons.eot') format("embedded-opentype"), url('fonts/v12-icons.woff') format("woff"), url('fonts/v12-icons.svg') format("svg"), url('fonts/v12-icons.ttf') format("truetype");
  font-weight: normal;
  font-style: normal; }

#mainContainer {
  margin-left: $leftMenuCollapsedWidth_UI2;
  transition: margin-left .4s; // Timing to use when collapsing & expanding leftMenu
  .menuExpanded & {
    margin-left: $leftMenuExpandedWidth_UI2; }
  .menuHidden & {
    margin-left: 0; }
  .iframe-body & {
    margin-left: 0 !important; } }

#main {
  box-sizing: border-box;
  margin: 0 1.3%;
  padding-top: ($topBarHeight_UI2 * 1.25);
  &.iframe {
    padding: 20px 12px 0 12px; } }

#verticalNav {
  .content {
    display: none; }
  .top {
    padding: 9px 0;
    .icon-up-arrow {
      padding: 5px 2px;
      border-top: 1px solid #ccc;
      width: 10px;
      display: inline-block; } } }

// On small screens, force the menu into compact mode
@media screen and (max-width: 1400px) {
  .menuExpanded {
    #mainContainer {
      margin-left: $leftMenuCollapsedWidth_UI2; } } }
