.pageMargin {
  margin: 0 1.04167%; }

#leftMenu-ui2 {
  box-sizing: border-box;
  position: fixed;
  background-color: $simproBlue;
  z-index: $zIndex_leftMenu;
  height: 100%;
  width: $leftMenuCollapsedWidth_UI2;
  left: 0;
  top: 0;
  transition: width .4s; // Timing to use when collapsing & expanding
  .menuExpanded & {
    width: $leftMenuExpandedWidth_UI2; }
  *, *:before, *:after {
    box-sizing: inherit; } }

.icon-leftMenu-toggle {
  display: inline-block;
  transition: transform 0.4s;
  &:before {
    content: unicode("e912");
    position: relative;
    top: -6px;
    left: 1px;
    color: #fff !important;
    font-size: 40px; }
  .menuExpanded & {
    transform: scaleX(-1);
    &:before {
      left: 0; } }
  + .leftMenuItem-detail {
    vertical-align: 22px; }
  &.leftMenuItem-icon {
    left: 5px;
    bottom: 1px; } }

.leftMenu-hideLabel {
  position: relative;
  top: 2px;
  left: 1px; }

#leftMenuInner-ui2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  hr {
    border-top: 1px solid $lighterBlue;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px; } }

.leftMenu-logoContainer {
  font-size: 28px;
  height: 40px;
  padding-top: ($topBarHeight_UI2 - $expandedLogoHeight_UI2) / 2;
  margin-bottom: 7px; }

#logoCollapsed {
  opacity: 1;
  display: block;
  text-align: center;
  animation: fademenu 0.5s;
  font-size: 26px;
  .menuExpanded & {
    display: none; } }

#logoExpanded {
  height: $expandedLogoHeight_UI2;
  display: none;
  margin: 0 auto;
  animation: fademenu 0.5s;
  .menuExpanded & {
    display: block; } }

.leftMenu-itemsList {
  width: 100%; }

.leftMenuItem {
  display: block;
  height: 42px;
  white-space: nowrap;
  margin-left: 5px;
  padding: 11px 0 31px 3px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  .leftMenuItem-parent, a {
    color: #FFF; }
  .menuClick &:hover {
    background-color: $darkBlue; }
  &.selected {
    background-color: $darkBlue;
    .leftMenuItem-icon {
      color: #ffffff; }
    .leftMenu-submenu {
      display: flex;
      flex-direction: column; } }
  &:has(span.leftMenu-hideLabel) {
    height: 50px; } }

@keyframes fademenu {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.leftMenu-submenu {
  display: none;
  position: fixed;
  background: $darkBlue;
  left: $leftMenuCollapsedWidth_UI2;
  z-index: 101;
  color: #FFF;
  transition: left 0.4s;
  cursor: auto;
  border-radius: 0 3px 3px 0;
  .menuExpanded & {
    left: $leftMenuExpandedWidth_UI2; }
  li {
    border-left: 3px solid transparent;
    padding: 0;
    margin-right: 5px;
    &.leftSubmenu-small {
      margin: -2px 5px 3px 5px;
      padding: 0 15px;
      font-size: 12px;
      color: #a9a9aa;
      font-weight: normal; } } }

.leftSubmenu-columnContainer {
  display: flex;
  padding-bottom: 3px; }

.leftSubmenu-column {
  min-width: 165px;
  max-width: 250px; }

.leftSubmenu-columns-3:nth-of-type(2) {
  min-width: 210px !important; }

.leftSubmenuItem {
  a {
    display: block;
    cursor: pointer;
    font-size: 14px;
    padding: 7px 15px;
    font-weight: normal; }
  &:hover {
    background-color: #206897;
    border-left: 3px solid;
    a {
      color: #fff !important; } }
  .tooltip {
    color: #232A2F !important;
    font-size: 12px !important; } }

.leftMenuItem-icon {
  font-size: 24px;
  position: relative;
  left: 3px;
  bottom: 2px;
  color: $lighterBlue; }

.leftSubmenuItem-icon {
  vertical-align: -2px;
  margin-right: 4px; }

.leftSubmenuItem-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

#leftMenu-toggle-ui2 {
  background-color: $lightSimproBlue;
  position: absolute;
  bottom: 0;
  > li {
    a {
      color: white; }
    &:hover {
      background-color: $lightSimproBlue; }
    &.selected {
      background-color: $lightSimproBlue; } } }

.leftMenuItem-detail {
  font-size: 14px;
  vertical-align: 8px;
  padding-left: 15px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease 0s; // Timing to use when collapsing
  .menuExpanded & {
    transition: opacity 0.4s ease 0.2s; // Timing to use when expanding
    opacity: 1; } }

.submenuTitle {
  margin: 5px 5px 0 5px;
  font-size: 16px;
  font-weight: bold;
  padding: 0 15px; }

.leftSubmenu-header {
  margin-left: 10px;
  font-size: 18px; }

.blueScrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 6px;
  background-color: $darkBlue; }

.blueScrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: $darkBlue; }

.blueScrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: $lightSimproBlue; }

.positionBottom {
  bottom: 0 !important; }

// On small screens, force the menu into compact mode
@media screen and (max-width: 1400px) {
  #leftMenu-toggle-ui2 {
    display: none; }
  .menuExpanded {
    #logoCollapsed {
      display: block; }
    #logoExpanded {
      display: none; }
    #leftMenu-ui2 {
      width: $leftMenuCollapsedWidth_UI2; }
    .leftMenu-submenu {
      left: $leftMenuCollapsedWidth_UI2; }
    .leftMenuItem-detail {
      opacity: 0; } } }


#leftMenu-items-ui2 {
  .leftMenuItem {
    .divider {
      border-top: 1px solid $lighterBlue;
      width: 95%;
      height: 1px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5px; } } }

.left-submenu-addons {
  li {
    border-left: 3px solid transparent;
    &:hover {
      background-color: #206897;
      border-left-color: $simproPaymentsYellow; } } }

.addon-menu-simpro-payments {
  font-family: $simproPaymentsFontFamily;
  font-weight: 400;
  color: $simproPaymentsYellow;
  text-transform: uppercase;
  display: flex;
  font-size: 14pt;
  padding-left: 15px;
  gap: 6px;
  margin-top: 5px;
  margin-bottom: 5px;
  img {
    width: 80px; }
  span {
    padding-top: 5px; } }
